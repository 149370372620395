export class DonationHistoryPage {
    monetaryDonationHistoryTab: HTMLButtonElement;
    monetaryDonations: HTMLDivElement;
    ticketDonationHistoryTab: HTMLButtonElement;
    ticketDonations: HTMLDivElement;
    constructor() {
        this.monetaryDonationHistoryTab = document.querySelector('[data-monetary-donation-tab]');
        this.monetaryDonations = document.getElementById('Monetary') as HTMLDivElement;
        this.ticketDonationHistoryTab = document.querySelector('[data-ticket-donation-tab]');
        this.ticketDonations = document.getElementById('Ticket') as HTMLDivElement;
        this.init();
    }
    init() {
        this.monetaryDonationHistoryTab?.addEventListener('click', () => {
            this.showMonetaryDonationHistory();
        });
        this.ticketDonationHistoryTab?.addEventListener('click', () => {
            this.showTicketDonationHistory();
        });
    }

    showMonetaryDonationHistory() {
        this.ticketDonations.setAttribute('aria-hidden', 'true');
        this.monetaryDonations.setAttribute('aria-hidden', 'false');
        this.monetaryDonationHistoryTab.classList.add('active');
        this.ticketDonationHistoryTab.classList.remove('active');
    }

    showTicketDonationHistory() {
        this.ticketDonations.setAttribute('aria-hidden', 'false');
        this.monetaryDonations.setAttribute('aria-hidden', 'true');
        this.monetaryDonationHistoryTab.classList.remove('active');
        this.ticketDonationHistoryTab.classList.add('active');
    }
}

new DonationHistoryPage();