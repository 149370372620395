// Resize Sensor will ensure the sticky sidebar is able to update itself when the browser is resized
import 'css-element-queries/src/ResizeSensor.js';
import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar.js';

export class StickySidebarComponent {
    topMargin: number;
    bottomMargin: number;
    breakpoint: number;
    stickySidebar: any;



    constructor(sidebar: HTMLElement) {
      
        this.topMargin = parseInt(sidebar.getAttribute('data-margin-top'));
        this.bottomMargin = parseInt(sidebar.getAttribute('data-margin-bottom'));
        this.breakpoint = parseInt(sidebar.getAttribute('data-sticky-for'));
        this.updateStickySidebar = this.updateStickySidebar.bind(this);

        this.stickySidebar = new StickySidebar(
            `#${sidebar.id}`,
            {
                containerSelector: '[data-sticky-container]',
                innerWrapperSelector: '[data-sticky-inner]',
                topSpacing: this.topMargin,
                bottomSpacing: this.bottomMargin,
                minWidth: this.breakpoint
            }
        );
        // Update it after a beat, in case anything needs to finish loading
        window.requestAnimationFrame(() => {
            this.updateStickySidebar();
        });
    }

    updateStickySidebar() {
        if (this.stickySidebar) {
            this.stickySidebar.updateSticky();
        }
    }
}

const stickyContainerEls = [].slice.call(document.querySelectorAll('[data-sticky-el]')) as HTMLElement[];
stickyContainerEls.forEach(stickyEl => {
    new StickySidebarComponent(stickyEl);
})