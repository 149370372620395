import { lockBody, unlockBody } from '../helpers/lockBody';

// Static strings
const openMenuClass = 'nav-menu-is-open';
const openSearchClass = 'nav-search-is-open';
const navLoadedClass = 'nav-has-loaded';
const menuSlideStateAttribute = 'data-nav-is-showing';
const navId = "nav-container";
const subNavId = "utility-nav-container";
const menuId = "nav-mobile-menu";
const searchId = "navSearch";
const anchorNav = "pageNav"

class MainNavigation {
    container: HTMLElement;
    utilityContainer: HTMLElement;
    anchorNav: HTMLElement;
    globalAlert: HTMLElement;
    menuTrigger: HTMLButtonElement;
    searchTrigger: HTMLButtonElement;
    menuPanel: HTMLElement;
    searchContainer: HTMLElement;
    menuFocusableChildren: Array<HTMLButtonElement | HTMLAnchorElement | HTMLInputElement>;
    menuIsOpen: boolean;
    searchIsOpen: boolean;
    navIsShowing: boolean;
    existingBodyStyles: string;
    lastScrollPosition: number;
    hasLoaded: boolean;
    currentNavHeight: number;
    bodyEl: HTMLBodyElement;
    onPageAnchorLinks: HTMLAnchorElement[];
    navSearchCloseBtn: null | HTMLElement;
    subnavDisabledPage: boolean;
    background: HTMLElement;
    innerNav: HTMLElement;
    triggers: HTMLElement[] | any;
    constructor(navigation: HTMLElement, utility: HTMLElement) {
        this.container = navigation;
        this.utilityContainer = utility;
        this.menuTrigger = this.container.querySelector(`[aria-controls="${menuId}"]`) as HTMLButtonElement;
        this.searchTrigger = this.container.querySelector(`[data-search-trigger]`) as HTMLButtonElement;
        this.anchorNav = document.querySelector(`#${anchorNav}`) as HTMLElement;
        this.globalAlert = document.querySelector(`[data-global-alert]`) as HTMLElement;
        this.menuPanel = document.querySelector(`#${menuId}`) as HTMLElement;
        this.searchContainer = document.querySelector(`#${searchId}`) as HTMLElement;
        this.menuFocusableChildren = [].slice.call(this.menuPanel.querySelectorAll('button, a, input'));
        this.bodyEl = document.body as HTMLBodyElement;
        this.menuIsOpen = false;
        this.searchIsOpen = false;
        this.navIsShowing = true;
        this.hasLoaded = false;
        this.existingBodyStyles = '';
        this.navSearchCloseBtn = null;
        this.subnavDisabledPage = false;
        this.background = document.querySelector('.primary-nav-subnav-container');
        this.innerNav = document.querySelector('.primary-nav');
        this.triggers = Array.from(document.querySelectorAll('[data-primary-nav-trigger]'));

        // Intercept on-page links so that the offset recalculates before the browser reaches it
        // This makes sure that the target is always comfortably visible below the nav
        this.onPageAnchorLinks = [].slice.call(document.querySelectorAll('a[href^="#"]'));
        this.onPageAnchorLinks.forEach(link => {
            link.addEventListener('click', (e: Event) => {
                e.preventDefault();
                const hash = link.getAttribute('href');
                const target = document.querySelector(hash) as HTMLElement;
                if (target) {
                    if (this.navIsShowing && target.offsetTop > window.pageYOffset) {
                        this.slideUp();
                    }
                    else if (!this.navIsShowing && target.offsetTop < window.pageYOffset) {
                        this.slideDown();
                    }
                    setTimeout(() => {
                        target.scrollIntoView(true);
                        target.focus();
                        window.location.hash = hash;
                    }, 0);
                }
            })
        })

    }

    init = () => {
        this.menuTrigger.addEventListener('click', () => {
            if (this.menuIsOpen) {
                this.closeMenu();
            }
            else {
                if (this.searchIsOpen) {
                    this.closeSearch();
                }
                this.openMenu();
            }
        });

        this.searchTrigger.addEventListener('click', () => {
            if (this.searchIsOpen) {
                this.closeSearch();
            }
            else {
                if (this.menuIsOpen) {
                    this.closeMenu();
                }
                this.openSearch();
            }
        });

        this.triggers.forEach(trigger => {
            trigger.addEventListener('mouseenter', (e) => {
                this.handleEnter(trigger)
            })
        })
        this.triggers.forEach(trigger => {
            trigger.addEventListener('mouseleave', (e) => {
                this.handleLeave(trigger)
            })
        })


        document.body.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.which == 27 && this.menuIsOpen) { // Escape key
                this.closeMenu();
                this.menuTrigger.focus();
            }
            if (e.which == 9) { // Tab key. Show nav if tabbed into, or close menu if tabbed out of.
                const focusInNav = this.container.contains(document.activeElement);
                if (focusInNav && !this.navIsShowing) {
                    this.slideDown();
                }
                if (!focusInNav && this.menuIsOpen) {
                    this.closeMenu();
                }

                this.triggers.forEach(trigger => {            
                    if (trigger.contains(document.activeElement)) {
                        this.handleEnter(trigger)
                    }
                    else {
                        this.handleLeave(trigger)

                    }
                })
            }
        });

        // Setup on page load
        this.closeMenu();
        this.setPageOffset();
        this.slideDown();
    }

    slideDown = () => {
        this.utilityContainer.setAttribute(menuSlideStateAttribute, 'true');
        this.navIsShowing = true;
        this.setCssVariables();
    }

    slideUp = () => {
        this.utilityContainer.setAttribute(menuSlideStateAttribute, 'false');
        this.navIsShowing = false;
        this.setCssVariables();
    }

    openMenu = () => {
        this.menuPanel.setAttribute('aria-hidden', 'false');
        this.menuTrigger.setAttribute('aria-expanded', 'true');
        this.enableTabbableChildren();
        this.menuIsOpen = true;
        this.existingBodyStyles = lockBody();
        this.container.classList.add(openMenuClass);

    };


    closeMenu = () => {
        this.menuPanel.setAttribute('aria-hidden', 'true');
        this.menuTrigger.setAttribute('aria-expanded', 'false');
        this.disableTabbableChildren();
        this.menuIsOpen = false;
        unlockBody(this.existingBodyStyles);
        this.container.classList.remove(openMenuClass);
        this.setPageOffset();
    }

    openSearch = () => {
        this.searchContainer.setAttribute('aria-hidden', 'false');
        this.searchTrigger.setAttribute('aria-expanded', 'true');
        this.searchIsOpen = true;
        this.container.classList.add(openSearchClass);
    };

    closeSearch = () => {
        this.searchContainer.setAttribute('aria-hidden', 'true');
        this.searchTrigger.setAttribute('aria-expanded', 'false');
        this.searchIsOpen = false;
        this.container.classList.add(openSearchClass);
    }

    enableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = 0;
        })
    }

    disableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = -1;
        })
    }

    setPageOffset = () => {
        if (!this.hasLoaded) {

            this.container.classList.add(navLoadedClass);
            this.utilityContainer.classList.add(navLoadedClass);
            this.hasLoaded = true;
        }
        this.currentNavHeight = this.container.offsetHeight;
        this.setCssVariables();
    }

    scrollHandler = () => {
        if (this.hasLoaded) {
            const currentPosition = window.pageYOffset;
            const delta = currentPosition - this.lastScrollPosition;
            this.lastScrollPosition = currentPosition;
            if (this.navIsShowing) {
                if (delta > 0 && currentPosition > this.currentNavHeight && !this.menuIsOpen) {
                    this.slideUp();
                }
            }
            else if (delta < 0 || currentPosition < this.currentNavHeight) {
                this.slideDown();
            }
        }
    }

    resizeHandler = () => {
        this.setPageOffset();
    }

    setCssVariables = () => {
        document.documentElement.style.setProperty('--nav-clearance', `${this.navIsShowing ? this.currentNavHeight + this.utilityContainer.offsetHeight : this.currentNavHeight}px`);
        document.documentElement.style.setProperty('--nav-margin-offset', `${this.navIsShowing ? this.currentNavHeight : 0}px`);
        document.documentElement.style.setProperty('--nav-utility-offset', `${this.navIsShowing ? this.utilityContainer.offsetHeight : 0}px`);
        document.documentElement.style.setProperty('--nav-anchor-offset', `${this.anchorNav ? this.anchorNav.offsetHeight : 0}px`);
        document.documentElement.style.setProperty('--nav-global-alert', `${this.globalAlert ? this.globalAlert.offsetHeight : 0}px`);
    }

    handleEnter = (e) => {
        if (!this.searchIsOpen) {
            e.classList.add('trigger-enter');
            this.innerNav.classList.add('open');
            setTimeout(() => e.classList.contains('trigger-enter') && e.classList.add('trigger-enter-active'), 150);
            const dropdown = e.querySelector('.primary-nav-subnav');
            dropdown.setAttribute('aria-expanded', true)
            const dropdownCoords = dropdown.getBoundingClientRect();
            const navCoords = this.innerNav.getBoundingClientRect();

            const coords = {
                height: dropdownCoords.height,
                width: dropdownCoords.width,
                top: dropdownCoords.top - navCoords.top,
                left: dropdownCoords.left - navCoords.left
            };

            this.background.style.setProperty('width', `${coords.width}px`);
            this.background.style.setProperty('height', `${coords.height}px`);
            this.background.style.setProperty('transform', `translate(${coords.left}px, ${coords.top}px)`);
        }
    }


    handleLeave(e) {
        if (!this.searchIsOpen) {
            e.classList.remove('trigger-enter', 'trigger-enter-active');
            const dropdown = e.querySelector('.primary-nav-subnav');
            dropdown.setAttribute('aria-expanded', false)
            this.innerNav.classList.remove('open');

        }
    }


    get isTheMenuOpen() {
        return this.menuIsOpen;
    }
}

let mainNavigation: MainNavigation = null;
const navContainer = document.querySelector(`#${navId}`) as HTMLElement;
const utilityContainer = document.querySelector(`#${subNavId}`) as HTMLElement;
if (navContainer && utilityContainer) {
    mainNavigation = new MainNavigation(navContainer, utilityContainer);
    (window as any).mainNavigation = mainNavigation;
}

export { mainNavigation };