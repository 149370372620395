export class QuickDonate {
    blockEl: HTMLFormElement;
    donationInput: HTMLInputElement;
    amount: number | any;
    customAmountActive: boolean;
    customRadio: HTMLInputElement;

    constructor(block: HTMLFormElement) {
        this.blockEl = block;
        this.donationInput = block.querySelector('[data-donate-input]');
        this.customRadio = block.querySelector('[data-custom-radio]');
        this.init();
    }

    init = () => {
        this.initRadioListeners();
        this.handleCustomAmountField();

        this.donationInput.addEventListener('change', (e) => {
            this.customRadio.value = this.donationInput.value;
        });
    }

    handleCustomAmountField = () => {
        if (this.customRadio.checked) {
            this.donationInput.parentElement.setAttribute("aria-hidden", "false")
            this.donationInput.focus();
        }

        else {
            this.donationInput.parentElement.setAttribute("aria-hidden", "true")
        }
    }

    initRadioListeners = () => {
        const allRadios = Array.prototype.slice.call(document.querySelectorAll('input[name="amount"]'));

        allRadios.forEach(radio => {
            if (radio.checked) {
                this.donationInput.value = radio.value;
            }
            radio.addEventListener('change', () => {
                allRadios.forEach(radio_ => {
                    if (radio_.checked) {
                        this.donationInput.value = radio_.value;
                        this.handleCustomAmountField()
                    }
                });
            });
        });
    }
}