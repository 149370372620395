import { donationInterruptPage } from './ace/pages/donationInterruptPage/donationInterruptPage';
import { Alert } from './modules/ace-alert';
import { showHidePassword } from './modules/show-hide-password';
import { quickDonate } from './ace/pages/quickDonate/quickDonatePage';
import CartTimer from './modules/cart-timer';
import { SvgSprite } from './modules/svg-loader';
import { Modal } from './modules/a11y-modal';
import { StateDropdown } from './modules/stateDropdown';
import { RevealPanel } from './modules/revealPanel';
import { Accordion } from './modules/accordion';
import { Tabs } from './modules/tabs';
import { mainNavigation } from './modules/mainNavigation';
import AddressApi from './modules/address-api';
import { StateSummary } from './ace/classes/address';
import { GoogleTranslate } from './modules/google-translate'
import * as YouTubeIframeLoader from 'youtube-iframe';
import './modules/promo-interface';
import './modules/sticky-sidebar-component';
import { Gallery } from './modules/gallery';
import { QuickDonate } from './modules/quickDonate';
import { HeroVideo } from './modules/heroVideo';
import { VideoItem } from './modules/videoItem';
import { vhUnitUpdate, vhUnitInitial } from './helpers/vhUnitFix';
import throttle = require('lodash.throttle');
import debounce = require('lodash.debounce');
import { DonationHistoryPage } from './pages/donation-history-page/donation-history-page';

new SvgSprite('/Static/img/adage-sprite.svg');
new CartTimer();
new GoogleTranslate();
new DonationHistoryPage();

donationInterruptPage();
showHidePassword();

// These are needed for the vh fix (to make mobile browsers happy)
let previousPageWidth = 0;
let previousPageHeight = 0;

const initializeGenericComponents = () => {


    quickDonate();
    navigationInit();

    const modals = Array.prototype.slice.call(document.querySelectorAll('[data-modal]')) as HTMLElement[];
    modals.forEach(modal => {
        new Modal(modal);
    });

    const alerts = Array.prototype.slice.call(document.querySelectorAll('[data-alert]')) as HTMLElement[];
    alerts.forEach(alert => {
        new Alert(alert);
    });

    const panels = [].slice.call(document.querySelectorAll('[data-panel]')) as HTMLElement[];
    panels.forEach(panel => {
        new RevealPanel(panel);
    });

    const accordions = [].slice.call(document.querySelectorAll('[data-accordion-list]')) as HTMLElement[];
    accordions.forEach(accordionEl => {
        const oneOpenAtATime = accordionEl.getAttribute('data-one-open-at-a-time') == "true";
        new Accordion({ container: accordionEl, oneOpenAtATime: oneOpenAtATime });
    });

    const tabEls = Array.prototype.slice.call(document.querySelectorAll('[data-tabs-container]')) as HTMLElement[];
    tabEls.forEach(tabEl => {
        new Tabs({ container: tabEl });
    })

    // Gallery Init
    document.addEventListener('DOMContentLoaded', function () {
        const galleryClass = 'gallery';
        const galleries = Array.prototype.slice.call(document.querySelectorAll('[data-gallery]'));
        galleries.forEach((gallery, index) => {
            gallery.classList.add(`${galleryClass}-${index}`);
            new Gallery(gallery);
        });
    });

    const quickDonateBlocks = [].slice.call(document.querySelectorAll('[data-quick-donate]'));
    quickDonateBlocks.forEach(block => {
        new QuickDonate(block);
    });

    // Hero videos
    const heroVideoContainers = Array.prototype.slice.call(document.querySelectorAll('[data-hero-video-container]')) as HTMLElement[];
    heroVideoContainers.forEach(heroVideoContainer => {
        new HeroVideo(heroVideoContainer);
    });

    // Videos
    const videoItems = Array.prototype.slice.call(document.querySelectorAll('[data-video-item]:not([data-video-type=youtube])')) as HTMLElement[];
    const youtubeVideoItems = Array.prototype.slice.call(document.querySelectorAll('[data-video-item][data-video-type=youtube]')) as HTMLElement[]
    videoItems.forEach(videoItem => {
        new VideoItem(videoItem);
    });
    YouTubeIframeLoader.load(YT => {
        youtubeVideoItems.forEach(youtubeVideoItem => {
            const iframe = youtubeVideoItem.querySelector('[data-youtube-id]');
            const ytvideoPlayer = new YT.Player(iframe.getAttribute('id'), {
                height: '390',
                width: '640',
                videoId: iframe.getAttribute('data-youtube-id'),
                events: {
                    onReady: () => {
                        new VideoItem(youtubeVideoItem, ytvideoPlayer);
                    }
                }
            });

        });

    });


}

const navigationInit = () => {
    if (mainNavigation) mainNavigation.init();

    // Setup for determining vh unit size
    previousPageWidth = window.innerWidth;
    previousPageHeight = window.innerHeight;

    document.addEventListener('scroll', throttle(() => {
        if (mainNavigation) mainNavigation.scrollHandler();
    }, 10));
    window.addEventListener('resize', debounce(() => {
        if (mainNavigation) mainNavigation.resizeHandler();
        resizeVhUpdates();
    }, 50));
}

const resizeVhUpdates = () => {
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;

    // Smaller resizes might be on scroll, so this measures if it's safe to treat it as a fully new browser size and recalculate.
    if (Math.abs(previousPageWidth - pageWidth) > 60 || Math.abs(previousPageHeight - pageHeight) > 60) {
        vhUnitInitial();
    }

    // This will get called on scroll-triggered resizes, so can be used for full-height elements that have their own scrollbar.
    // if the body scrollbar is locked, then it requires someone force scrolling at the bottom to change the height at that point
    vhUnitUpdate();
    previousPageWidth = pageWidth;
    previousPageHeight = pageHeight;
}

const initalizeStateDropdowns = () => {
    const stateDropdown = document.querySelector('#State') as HTMLSelectElement;
    const countryDropdown = document.querySelector('#Country') as HTMLSelectElement;
    if (stateDropdown && countryDropdown) {
        new StateDropdown(stateDropdown, countryDropdown);
    }

    const countryDropDownList = document.querySelectorAll<HTMLSelectElement>(".js-country");

    countryDropDownList.forEach(countryDropDown => countryDropDown.addEventListener("change", (e) => {
        const selectedCountryId = parseInt(countryDropDown.value);
        const addressType = countryDropDown.getAttribute("data-address-type");
        AddressApi.getStatesForCountry(selectedCountryId).then(results => {
            updateStatesDropDown(results, addressType);
        });
    }));

    function updateStatesDropDown(states: Array<StateSummary>, addressType: string) {
        const stateDropDown = document.querySelector<HTMLSelectElement>(".js-state[data-address-type=" + addressType + "]");
        stateDropDown.innerHTML = '';
        if (states.length) {
            states.forEach(state => {
                const option = document.createElement('option');
                option.value = state.stateCode.toString();
                option.innerHTML = state.description;
                stateDropDown.appendChild(option);
            });
            stateDropDown.disabled = false;
        }
        else {
            stateDropDown.disabled = true;
        }
    }
}

initializeGenericComponents();
initalizeStateDropdowns();