export const quickDonate = () => {
    const donationAmountsList = document.querySelector('#Donation_amounts') as HTMLElement;
    const donationInput = document.querySelector('#Amounts_custom-field') as HTMLInputElement;
    const customRadio = document.querySelector('[data-custom-radio]') as HTMLInputElement;
    const customRadioContainer = document.querySelector('[data-custom-radio-container]') as HTMLElement;
    const customRadioInput = document.querySelector('[data-custom-radio-input]') as HTMLInputElement;
    const searchParams = new URLSearchParams(window.location.search);

    if (donationAmountsList && donationInput) {
        const allRadios = Array.prototype.slice.call(donationAmountsList.querySelectorAll('input'));

        donationInput.addEventListener('change', () => {
            const newValue = parseInt(donationInput.value);
            if (newValue) {
                allRadios.forEach(radio => {
                    if (parseInt(radio.value) == newValue) {
                        radio.checked = true;
                    }
                    else {
                        radio.checked = false;
                    }
                })
            }
        });
        donationInput.addEventListener('blur', () => {

            if (parseInt(donationInput.value) <= 0) {
                donationInput.value = null;
            }


        });
        allRadios.forEach(radio => {
            if (radio.checked) {
                donationInput.value = radio.value;
            }
            radio.addEventListener('change', () => {
                allRadios.forEach(radio_ => {
                    if (radio_.checked) {
                        donationInput.value = radio_.value;
                    }
                });
                handleCustomAmountField();
            });
        });

        if (customRadio.checked) {
            setTimeout(() => {
                customRadioContainer.setAttribute("aria-hidden", "false")
                customRadio.value = customRadio.value
            }, 1000)
        }
    }
    const handleCustomAmountField = () => {

        if (customRadio.checked) {
            customRadioContainer.setAttribute("aria-hidden", "false")
            donationInput.focus();
        }

        else {
            customRadioContainer.setAttribute("aria-hidden", "true")
        }
    }

    if (searchParams.has('amount') && customRadioInput) {
        setTimeout(() => {
            customRadioInput.value = searchParams.get('amount');
        }, 0)
    }

}