import Splide from '@splidejs/splide';
import { Video } from '@splidejs/splide-extension-video';
import { nodeListToArray } from '../helpers/nodeListToArray';
import { MyTransition } from './splide-transition';

const commonGalleryOptions = {
    accessibility: true,
    drag: true,
    // @ts-ignore
    pagination: false,
    // @ts-ignore
    slideFocus: false,
    easing: 'linear',
    speed: 600,
    interval: 5000,
    focus: 0,
    gap: '2rem',
    padding: {
        left: '0rem',
        right: '0rem'
    },
    arrows: true,
    keyboard: 'focused',
    video: {
        // setting to true is currently required due to a bug in the splide video extension creating
        // the embed url with an empty "playlist" parameter which break YouTube embed link
        loop: true
    },
    classes: {
        pagination: 'splide__pagination gallery-pagination gallery-pagination-nums',
        page: 'splide__pagination__page gallery-page'
    },

}

const gridGalleryOptions = {
    perPage: 4,
    perMove: 4,
    breakpoints: {
        1280: {
            perPage: 2,
            perMove: 2,
            gap: '1.5rem',
        },
        767: {
            perPage: 1,
            perMove: 1,
            gap: '1rem',
        }
    },
}

const defaultGalleryOptions = {
    perPage: 2,
    perMove: 2,
    breakpoints: {
        1280: {
            gap: '1.5rem'
        },
        767: {
            gap: '.5rem',
            perPage: 1,
            perMove: 1,
        }
    },
    updateOnMove: true,
    autoHeight: true,
    type: 'loop',
    
}


// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class Gallery {
    gallery: Splide;
    galleryType: string;
    galleryOptions: any;//Refer to splide options documentationt for available choices
    autoStart: string;
    setInterval: string;
    progressBar: HTMLElement;
    constructor(element: HTMLElement) {
        this.galleryType = element.getAttribute('data-gallery-type');
        this.autoStart = element.getAttribute('data-autoplay') != null ? element.getAttribute('data-autoplay') : "";
        // This needs to be set to a obscenely large number due to an interval bug with Splide
        this.setInterval = element.getAttribute('data-interval') != null ? element.getAttribute('data-interval') : "1000000";
        this.setGalleryOptions();
        this.gallery = new Splide(element, this.galleryOptions);
        this.gallery.on('mounted', () => {
            this.arrowDisplay();
         
            setTimeout(() => {
                const playButtons = nodeListToArray(this.gallery.root.querySelectorAll('.media-gallery-item .splide__slide__container--has-video'));
                playButtons.forEach(playButton => {
                    const slide = playButton.closest('.splide__slide');
                    const slideTarget = slide.querySelector('[data-gallery-video]');
                    slideTarget.append(playButton);
                });
            }, 1000);//Forgive me, but no event to listen to find out when the playbuttons are loaded. Splide also isn't respecting the splide__slide__container for the playbutton target so I had to do it manually here :(
        });
        this.gallery.on('resized', () => {
            this.arrowDisplay();
        });
        this.gallery.on('move', () => {
            this.gallery.root.classList.add('moving');
          
        });
        this.gallery.on('moved', () => {
            this.gallery.root.classList.remove('moving');
        });

        this.gallery.on('video:play', () => {
            const videoSelector = ".is-playing .is-active .splide__video__wrapper";
            const playingVideoElement = document.querySelector(`${videoSelector} video`) || document.querySelector(`${videoSelector} iframe`);
            if (playingVideoElement) {
                playingVideoElement.setAttribute('title', playingVideoElement.closest('[data-video-title]').getAttribute('data-video-title'));
            }

        });

        this.gallery.mount({ Video }, MyTransition);
    }
    arrowDisplay = () => {
        const galleryArrows = this.gallery.Components.Arrows.arrows;
        if (galleryArrows.next && galleryArrows.prev) {
            const arrowsShouldBeHidden = galleryArrows.next.hasAttribute('disabled') && galleryArrows.prev.hasAttribute('disabled');
            galleryArrows.next.hidden = arrowsShouldBeHidden;
            galleryArrows.prev.hidden = arrowsShouldBeHidden;

        }
    }
    setGalleryOptions = () => {
        const optionsToUse = this.galleryType == "grid" ? gridGalleryOptions : defaultGalleryOptions;
        const autoPlayOptions = {
            autoplay: this.autoStart.toString(),
            interval: this.setInterval.toString()
        }
        this.galleryOptions = {
            ...commonGalleryOptions,
            ...autoPlayOptions,
            ...optionsToUse
        }
    }
}