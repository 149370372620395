import Player  from '@vimeo/player';

enum VideoType {
    NATIVE = "native",
    VIMEO = "vimeo",
    YOUTUBE = "youtube",
    NONE = "none"
}

export class VideoItem {
    container: HTMLElement;
    video: HTMLIFrameElement | HTMLVideoElement;
    videoType: string;
    videoSrc: string;
    playButton: HTMLButtonElement;
    videoObject: any;
    youtubeVideoPlayer: any;
    constructor(container: HTMLElement, youtubeVideoPlayer?: any) {//Youbute video player'
        if (youtubeVideoPlayer)
            this.youtubeVideoPlayer = youtubeVideoPlayer;
        this.container = container;
        this.video = this.youtubeVideoPlayer ? this.container.querySelector('iframe') : this.container.querySelector('[data-video]');
        this.videoType = this.container.getAttribute('data-video-type');
        this.videoSrc = this.video.getAttribute('data-src');
        this.playButton = this.container.querySelector('[data-video-play]') as HTMLButtonElement;
        this.initializeVideo();
    }

    playPause() {
        // Youtube Play and Pause
        if (this.videoType == VideoType.YOUTUBE) {
            const playerState = this.videoObject.getPlayerState();
            //// == 1 means it's playing
            if (playerState == 1) {
                this.videoObject.pauseVideo();
            }
            else {
                this.videoObject.playVideo();
                if (this.videoObject.isMuted())
                    this.videoObject.unMute();
            }
        }

        // Native Play and Pause
        if (this.videoType == VideoType.NATIVE) {
            if (this.videoObject.paused) {
                this.videoObject.play();
            }
            else {
                this.videoObject.pause();
            }
            this.videoObject.controls = true;
        }

        // Vimeo Play and Pause
        if (this.videoType == VideoType.VIMEO) {
            this.videoObject.getPaused().then((paused: boolean) => {
                if (paused) {
                    this.videoObject.play();
                }
                else {
                    this.videoObject.pause();
                }
            })
        }

        this.container.classList.add('media-item-playing');
    }

    initializeVideo() {
        if (!this.video.src) {

            // Native video initialization
            if (this.videoType == VideoType.NATIVE) {
                this.video = this.video as HTMLVideoElement;
                const videoSource = this.video.querySelector('source');
                if (videoSource) {
                    videoSource.src = this.videoSrc;
                    this.videoObject = this.video;
                    this.videoObject.load();
                    this.videoObject.controls = false;
                }
            }

            // Vimeo video initialization
            if (this.videoType == VideoType.VIMEO) {
                this.video.src = this.videoSrc;
                const vimeoPlayer = new Player(this.video, {});
                this.videoObject = vimeoPlayer;
            }
        }
        else {
            // Youtube initialization and handlers
            if (this.videoType == VideoType.YOUTUBE) {
                this.videoObject = this.youtubeVideoPlayer;
            }
        }
        //Activate Playbutton
        this.playButton.addEventListener('click', this.playPause.bind(this));
    }
}