import { setCookie } from '../helpers/cookie';
export class Alert {
    alertElement: HTMLElement;

    constructor(alertElement: HTMLElement, container?: HTMLElement) {

        this.alertElement = alertElement;

        if (this.alertElement.querySelector('[data-close]')) {
            this.alertElement.querySelector('[data-close]').addEventListener('click', (alert) => {
                if (alertElement.hasAttribute('data-global-alert')) {
                    setCookie('CTG_GlobalAlert_Closed', 'True', 7);
                }
                this.alertElement.style.display = 'none';
            })
        }
    }
}