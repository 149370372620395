import creditCardType = require('credit-card-type');
import { CreditCardType } from 'credit-card-type/dist/types';
import { Toggletip } from './toggletip';

// Constants
// This sets the attribute of the hidden input that contains the list of accepted credit card types in a pipe-delineated list of "name:value"
const allowedTypesAttribute = 'data-allowed-types';

// name for the "Credit Card" payment type (lowercase with punctuation/spaces removed) in the type list, to attach to card numbers that don't match any known pattern
const defaultCreditCardName = 'creditcard';

// CSS class for injected card images
const cardImageClass = 'acceptance-mark';

// ID for toggletip panel
const toggletipId = 'toggletip_cards';

const acceptanceMarksPath = '\\Static\\img\\acceptance-marks\\';
// The spelling here is from our logo files. It matches the js library spelling, not Tessitura's.
const cardImages = ['american-express', 'diners-club', 'discover', 'elo', 'hipercard', 'jcb', 'maestro', 'mastercard', 'mir', 'unionpay', 'visa'];

interface CardType {
    standardizedType: string,
    value: number,
    card: CreditCardType,
    imagePath: string
}

export class LabeledCardNumber {
    numberInput: HTMLInputElement;
    hiddenTypeInput: HTMLInputElement;
    defaultCardTypeId: number;
    allowedTypes: string[];
    inputContainer: HTMLElement;
    cardImageElement: HTMLImageElement;
    cardTypes: CardType[];
    currentType: number;
    toggletipElement: HTMLElement;

    constructor(inputContainer: HTMLElement) {
        this.inputContainer = inputContainer;
        this.numberInput = inputContainer.querySelector('input[type="text"]') as HTMLInputElement;
        this.hiddenTypeInput = inputContainer.querySelector(`[${allowedTypesAttribute}]`) as HTMLInputElement;
        if (this.numberInput && this.hiddenTypeInput) {
            this.allowedTypes = this.hiddenTypeInput.getAttribute(allowedTypesAttribute).split('|');
            this.cardTypes = [];
            this.allowedTypes.forEach(type => {
                this.buildTypeObject(type);
            });
            this.injectImage();
            this.currentType = this.defaultCardTypeId;
            this.testCardNumber = this.testCardNumber.bind(this);
            this.numberInput.addEventListener('keyup', this.testCardNumber);

            // Initialize toggletip with accepted cards if in the view
            this.toggletipElement = document.querySelector(`#${toggletipId}`) as HTMLElement;
            if (this.toggletipElement) {
                this.buildToggletip();
            }
        }
    }

    buildTypeObject(type: string) {
        const standardizedType = this.standardizeSpelling(type);
        const typeValue = parseInt(type.split(':')[1]);
        if (standardizedType == defaultCreditCardName) {
            this.defaultCardTypeId = typeValue;
        }
        const cardPatternNames = cardImages.filter(patternName => {
            return this.standardizeSpelling(patternName) == standardizedType;
        });
        const cardPatternName = cardPatternNames.length > 0 ? cardPatternNames[0] : null;
        const cardConfig: CardType = {
            standardizedType: standardizedType,
            value: typeValue,
            card: cardPatternName ? creditCardType.getTypeInfo(cardPatternName) : null,
            imagePath: cardPatternName ? `${acceptanceMarksPath}${cardPatternName}.png` : null
        };
        this.cardTypes.push(cardConfig);
    }

    testCardNumber() {
        const cardNumber = this.numberInput.value;
        if (cardNumber.length > 2) {
            const cardMatches = creditCardType(cardNumber);
            const intersectingCardMatches = this.cardTypes.filter(typeObject => {
                const match = cardMatches.filter(cardType => {
                    return typeObject?.card?.type == cardType.type;
                });
                return match.length > 0;
            });
            if (intersectingCardMatches.length > 0) {
                this.setCurrentCardType(intersectingCardMatches[0]);
            }
            else {
                this.setCurrentCardType(null);
            }
        }
    }

    setCurrentCardType(type: CardType) {
        if (type == null) {
            this.cardImageElement.src = '';
            this.cardImageElement.alt = '';
            this.cardImageElement.hidden = true;
            this.hiddenTypeInput.value = `${this.defaultCardTypeId}`;
            this.numberInput.removeAttribute('maxLength');
        }
        else if (type.card != null && type.value != this.currentType) {
            this.currentType = type.value;
            this.cardImageElement.src = type.imagePath;
            this.cardImageElement.alt = type.card.niceType;
            this.cardImageElement.hidden = false;
            this.hiddenTypeInput.value = `${type.value}`;
            // The library gives us the possible lengths in an array, i.e. [13, 16] so we can set the max of the text input
            this.numberInput.maxLength = type.card.lengths.reduce((prev, next) => { return Math.max(prev, next) });
        }
    }

    standardizeSpelling(term: string) {
        return term.split(':')[0].toLowerCase().replace(/[_-\s]+/g, '');
    }

    createImg() {
        const image = document.createElement('img');
        image.className = cardImageClass;
        return image;
    }

    injectImage() {
        this.cardImageElement = this.createImg();
        this.cardImageElement.hidden = true;
        this.inputContainer.appendChild(this.cardImageElement);
    }

    buildToggletip() {
        new Toggletip(this.toggletipElement);
        const cardWrapper = document.createElement('p');
        this.cardTypes.forEach(cardType => {
            if (cardType.imagePath) {
                const cardImage = this.createImg();
                cardImage.src = cardType.imagePath;
                cardImage.alt = cardType.card.niceType;
                cardWrapper.appendChild(cardImage);
            }
        });
        this.toggletipElement.appendChild(cardWrapper);
    }
}