export class GoogleTranslate {
    COOKIE_NAME: string = 'googtrans';
    translateRadioInputs: Array<HTMLInputElement>;

    constructor() {
        this.initializeTranslateInputListeners();
        this.initializeLangMutationObserver();
    }

    onLanguageChange(e) {
        const radio = e.target as HTMLInputElement;
        const selectedLanguage = radio?.value || 'en';

        this.updateState(selectedLanguage);

        location.reload();
    }

    initializeTranslateInputListeners() {
        const translatePanels = document.querySelectorAll<HTMLElement>('[data-translate-panel]');
        const translateRadioInputs = Array.from(translatePanels).flatMap(elm => Array.from(elm.querySelectorAll('input')));
        this.translateRadioInputs = translateRadioInputs;

        if (translateRadioInputs?.length > 0) {
            const self = this;
            translateRadioInputs.forEach(radio => radio.addEventListener('change', this.onLanguageChange.bind(self)));
        }
    }

    // Observes the mutation of the 'lang' attribute on the <html> element
    // and upon a change, it updates the google translate form element to reflect the language change.
    //
    // This is important because the user can modify the lang of the page through UI which we don't have
    // event listeners set up for.
    //
    initializeLangMutationObserver() {
        // If no translate element was found, then we don't need to observe the  
        if (!this.translateRadioInputs || this.translateRadioInputs.length === 0) {
            return;
        }

        // Select the node that will be observed for mutations
        const htmlRoot = document.getElementsByTagName('html')[0];

        // Options for the observer (which mutations to observe)
        const config = { attributes: true };

        // Callback function to execute when mutations are observed
        const callback: MutationCallback = (mutationList, observer) => {
            for (const mutation of mutationList) {
                const { type, attributeName, target } = mutation;
                const newLang = (target as HTMLElement)?.getAttribute('lang') ?? 'en';

                if (type === 'attributes' && attributeName === 'lang') {
                    this.translateRadioInputs.forEach(languageOption => {
                        languageOption.checked = languageOption.value.endsWith(newLang);
                    })
                }
            }
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(htmlRoot, config);
    }

    // Cookie modifying code within is loosely taken from the JS that comes with Google Translate plugin
    // because it targets and modifies multiple cookies on page load, which have to be accounted for
    // when the user chooses a language from the dropdown.
    updateState(selectedLanguage: string) {
        let newCookie = this.COOKIE_NAME;

        if (selectedLanguage && selectedLanguage !== 'en') {
            newCookie += ('=/en/' + selectedLanguage);
        } else {
            let time = new Date();
            time.setTime(time.getTime() - 1);
            newCookie += '=none;expires=' + time.toUTCString();
        }

        // update default domain, e.g, centertheatregroup-dev.adagetech.net
        newCookie += ';path=/';
        document.cookie = newCookie;

        // update potential base domain
        let hostnameParts = window.location.hostname.split('.');
        // e.g, [centertheatregroup-dev, adagetech, net]
        let baseDomain = (hostnameParts.length <= 2
            ? hostnameParts
            : hostnameParts.slice(hostnameParts.length - 2, hostnameParts.length)).join('.');

        try {
            document.cookie = newCookie + ';domain=' + baseDomain;
        } catch (d) { }
    }
}