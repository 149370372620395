(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("_"));
	else if(typeof define === 'function' && define.amd)
		define(["_"], factory);
	else if(typeof exports === 'object')
		exports["Library"] = factory(require("_"));
	else
		root["Library"] = factory(root["_"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_lodash__) => {
return 